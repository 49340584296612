<template>
  <div class="common-page">
    <van-loading type="spinner" />
  </div>
</template>

<script>
import { Loading } from "vant";
import url from "url";
import { authToken } from "@/api/common";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Common",
  components: {
    [Loading.name]: Loading,
  },
  computed: {
    ...mapState(["mallInfo"]),
  },
  watch: {
    mallInfo: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal.mallName != oldVal.mallName) {
          document.title = newVal.mallName;
        }
      },
    },
  },
  created() {
    /**
     * APP进入携带 code和state
     * state为待跳转的商户页面地址
     */
    const state = this.$route.query.state;
    //  获取是否为微信环境
    if (state) {
      // 如果有state  则为app进入商城  拿到state后跳转到该url地址
      const loginCode = this.$route.query.code;
      const xUrl = url.parse(state, true);
      xUrl.query.loginCode = loginCode;
      xUrl.query.pageUrl = encodeURIComponent(state);

      const nowUrl = url.parse(window.location.href, true);
      xUrl.query.redirect_url = `${nowUrl.protocol}//${nowUrl.host}/common`;

      const xSearch = [];
      Object.keys(xUrl.query).forEach((itemKey) => {
        if (itemKey && xUrl.query[itemKey] != null) {
          xSearch.push(`${itemKey}=${xUrl.query[itemKey]}`);
        }
      });
      const searchStr = xSearch.length ? `?${xSearch.join("&")}` : "";
      const redirectUrl = `${xUrl.protocol}//${xUrl.host}/common${searchStr}`;
      window.location.replace(redirectUrl);
      return;
    } else {
      // 自定义跳转页面
      const loginCode = this.$route.query.loginCode; // 登录code
      const redirect_url = this.$route.query.redirect_url;
      const pageUrl = this.$route.query.pageUrl || "";
      this.getToken(loginCode, redirect_url, pageUrl);
    }
  },
  mounted() {
    document.title = this.mallInfo.mallName;
  },
  methods: {
    ...mapMutations(["setLoginStatus"]),
    ...mapActions(["dispatchGetCardCount", "getUserInfo"]),
    async getToken(loginCode, redirect_uri, pageUrl) {
      const res = await authToken({
        code: loginCode,
        redirect_uri,
      });
      if (res && res.code == 0) {
        this.setLoginStatus({ status: true });
        localStorage.setItem("login_time", new Date().getTime());
        localStorage.setItem("access_token", res.data?.access_token);
        localStorage.setItem("refresh_token", res.data?.refresh_token);
        // 获取优惠券数量
        this.dispatchGetCardCount();
        // 获取用户信息
        this.getUserInfo();
        // 如果有优惠券code  跳转到优惠券页面
        setTimeout(() => {
          if (pageUrl) {
            const r_url = url.parse(pageUrl, true);
            console.log("第三方登录回来：", JSON.stringify(r_url));
            this.$router.replace({
              path: r_url.pathname || "/mall/home",
              query: r_url.query,
            });
            // 使用下面的方法 获取不到路由对象
            // window.location.replace(pageUrl);
          } else {
            this.$router.replace("/mall/home");
          }
        }, 500);
      } else {
        this.$toast(res.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.common-page {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
